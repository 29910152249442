import { StaticImage } from "gatsby-plugin-image";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import ServiceBase from "../../components/service";

const TutorialVisualization = () => (
  <ServiceBase
    title={"Japanese Tutorial Video Service"}
    bgClass="bg-[url('../images/services/tutorial-visualization/banner.png')]"
  >
    <Fragment>
      <Helmet>
        <title>Japanese Tutorial Video Service | README Digital</title>
      </Helmet>
      <div className="grid md:grid-cols-2 gap-x-8 mb-[100px]">
        <StaticImage
          src="../../images/services/tutorial-visualization/feature-1.png"
          alt="Publish your manual docs as an eBook in Japanese"
          layout="fullWidth"
          className="mt-[-24px] mb-8"
        />
        <div>
          <h2 className="text-[36px] font-bold first-letter:text-[#F13D6F] leading-[60px] mb-[30px]">
            Japanese tutorial videos that empower your tier-0 support
          </h2>
          <p className="font-bold text-[18px]">
            Sometimes one video tutorial is worth hundreds of manual docs. The locally composed
            manual docs of your products by README Digital service can also be delivered as tutorial
            videos on YouTube. With localized subtitles or narration, README Digital_HOWTO videos
            will help your local partners/customers understand your product easier and faster.
            <br />
            This service is available as an add-on.
          </p>
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-x-8 mb-[100px]">
        <StaticImage
          src="../../images/services/tutorial-visualization/feature-2.png"
          alt="PDF delivery available in higher plans"
          layout="fullWidth"
          className="order-first mt-[-24px] md:order-last mb-8"
        />
        <div>
          <h2 className="text-[36px] font-bold first-letter:text-[#F13D6F] leading-[60px] mb-[30px]">
            MP4 delivery available in higher plans
          </h2>
          <p className="font-bold text-[18px] mb-6">
            The videos will be published in our YouTube brand account with your product name, and
            moreover, we also provide MP4 delivery service in higher plans. You will be able to
            utilize it for your own promotion, training, or any other purposes depending on your
            strategies, including publishing in your own channel.
          </p>
        </div>
      </div>
    </Fragment>
  </ServiceBase>
);

export default TutorialVisualization;
